import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

function FallbackScreen() {
  const navigate = useNavigate();

  return (
    <div>
      <h1 className="pdl-title-main">Este pago no te corresponde</h1>
      <h4 className="text-center">
        El pago que intentas visualizar no pertenece a tus reservas.
        <br />
        Verifica tus reservas y revisa el detalle de tu Match.
      </h4>
      <div className="py-2">
        <Button onClick={() => navigate("/gocalendar")} className="w-100">
          <FontAwesomeIcon icon={faCalendar} className="me-2" />
          Ir a reservas
        </Button>
      </div>
    </div>
  );
}

export default FallbackScreen;
