import React, { lazy, Suspense } from "react";

// Lazy loading screens
const LoginScreen = lazy(() => import("../../screens/GoMatch/LoginScreen"));
const HomeScreen = lazy(() => import("../../screens/GoMatch/HomeScreen"));
const FeedScreen = lazy(() => import("../../screens/GoMatch/FeedScreen"));
const CourtScreen = lazy(() => import("../../screens/GoMatch/CourtScreen"));
const RegisterScreen = lazy(() => import("../../screens/GoMatch/RegisterScreen"));
const BuildingMatchScreen = lazy(() => import("../../screens/GoMatch/BuildingMatchScreen"));
const ProfileScreen = lazy(() => import("../../screens/GoMatch/ProfileScreen"));
const EditProfileScreen = lazy(() => import("../../screens/GoMatch/EditProfileScreen"));
const BookingsScreen = lazy(() => import("../../screens/GoMatch/BookingScreen"));
const PaymentScreen = lazy(() => import("../../screens/GoMatch/PaymentScreen"));
const AdminLoginScreen = lazy(() => import("../../screens/Admin/AdminLoginScreen"));
const SharedProfileScreen = lazy(() => import("../../screens/GoMatch/SharedProfileScreen"));
const RankScreen = lazy(() => import("../../screens/GoMatch/RankScreen"));

export const privateRoutes = [
  {
    path: "/",
    element: <Suspense ><HomeScreen /></Suspense>,
  },
  {
    path: "/login",
    element: <Suspense ><LoginScreen /></Suspense>,
  },
  {
    path: "/register",
    element: <Suspense ><RegisterScreen /></Suspense>,
  },
  {
    path: "/match/:slug",
    element: <Suspense ><BuildingMatchScreen /></Suspense>,
  },
  {
    path: "/profile",
    element: <Suspense ><ProfileScreen /></Suspense>,
  },
  {
    path: "/user/:username",
    element: <Suspense ><SharedProfileScreen /></Suspense>,
  },
  {
    path: "/edit-profile/:username",
    element: <Suspense ><EditProfileScreen /></Suspense>,
  },
  {
    path: "/feed",
    element: <Suspense ><FeedScreen /></Suspense>,
  },
  {
    path: "/court",
    element: <Suspense ><CourtScreen /></Suspense>,
  },
  {
    path: "/reservas",
    element: <Suspense ><BookingsScreen /></Suspense>,
  },
  {
    path: "/rank",
    element: <Suspense ><RankScreen /></Suspense>,
  },
  {
    path: "/payment/:slug",
    element: <Suspense ><PaymentScreen /></Suspense>,
  },
  {
    path: "/admin_login",
    element: <Suspense ><AdminLoginScreen /></Suspense>,
  },
];
