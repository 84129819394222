  // Function to format RUT
  import { jwtDecode } from "jwt-decode";
  import moment from 'moment';

  export const formatRut = (rut) => {
    // Remove any non-digit characters (except 'K' and 'k')
    let cleanRut = rut.replace(/[^0-9kK]+/g, '').toUpperCase();
    // Add the hyphen if necessary
    if (cleanRut.length > 1) {
      cleanRut = cleanRut.slice(0, -1) + '-' + cleanRut.slice(-1);
    }
    return cleanRut;
  };

  // Function to validate RUT
  export const validateRut = (rut) => {
    // Regular expression for RUT format (e.g., 12345678-9)
    const pattern = /^(\d{1,8})-(\d|K)$/;

    if (!pattern.test(rut)) return false;

    const [body, dv] = rut.split('-');
    let suma = 0;
    let multiplo = 2;

    for (let i = body.length - 1; i >= 0; i--) {
      suma += multiplo * body[i];
      multiplo = multiplo === 7 ? 2 : multiplo + 1;
    }

    let dvCalc = 11 - (suma % 11);
    if (dvCalc === 11) dvCalc = '0';
    if (dvCalc === 10) dvCalc = 'K';

    return String(dvCalc).toUpperCase() === dv.toUpperCase();
  };

  export const splitSlug = (str) => {
    const slug = str.substring(0, 16);
    const id = str.substring(16);
  
    return { slug, id };
  };

  export const randomString = (length) => {
    let randomString = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return randomString;
  };

  // Boolean function to validate 24-hour time format (HH:mm). Example: 00:00, 14:30, 23:59, 24:00, etc
  // Does not acept format like: 30:30, 28:00, 24:60, etc
  export const validate24TimeFormat = (time) => {
      // Regular expression for 24-hour time format (HH:mm)
      const regex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d)$|^24:00$/;
      return regex.test(time);
  }

  export const formatCurrencyCLP = (amount) => {
    return new Intl.NumberFormat("es-CL", { currency: "CLP", style: "currency" }).format(amount);
  };

  export const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
  
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Could not create blob from canvas'));
            }
          }, 'image/png', 0.85); // Adjust quality as needed
        };
        img.onerror = () => {
          reject(new Error('Image loading error'));
        };
        img.src = e.target.result;
      };
      reader.onerror = () => {
        reject(new Error('FileReader error'));
      };
      reader.readAsDataURL(file);
    });
  };
  
  export const isTokenExpired = () => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('userToken');
    if (!token) {
        return true;
    }

    // Attempt to get the expiration date from localStorage
    const expToken = localStorage.getItem('expToken');
    const storedTokenHash = localStorage.getItem('tokenHash');
    const currentTokenHash = btoa(token); // Base64 encode the current token to create a simple hash

    // Check if the stored expiration corresponds to the current token
    if (!expToken || storedTokenHash !== currentTokenHash) {
        try {
            // Decode the token
            const decoded = jwtDecode(token);
            const expirationDate = new Date(decoded.exp * 1000);

            // Store the new expiration date and token hash
            localStorage.setItem('expToken', decoded.exp * 1000);
            localStorage.setItem('tokenHash', currentTokenHash);

            // Determine if the token has expired
            const isExpired = expirationDate < new Date();
            if (isExpired) {
                localStorage.clear(); // Clear localStorage if the token is expired
                // redirect to admin_login
                window.location.href = "/admin_login";
            } else {
            }
            return isExpired;
        } catch (error) {
            console.error("Failed to decode token:", error);
            return true;
        }
    } else {
        // Use the existing expiration date
        const expirationDate = new Date(parseInt(expToken, 10));

        // Determine if the token has expired
        const isExpired = expirationDate < new Date();
        if (isExpired) {
            localStorage.clear(); // Clear localStorage if the token is expired
        } else {
        }
        return isExpired;
    }
  };


  export const mapFrontendToBackend = (category) => {
    const mapping = {
      '1ra': 'M1ra', '2da': 'M2da', '3ra': 'M3ra', '4ta': 'M4ta',
      '5ta': 'M5ta', '6ta': 'M6ta', 'A': 'FA', 'B': 'FB', 'C': 'FC', 'D': 'FD', 'Sin categoría' : 'Sin categoría'
    };
    return mapping[category] || category;
  };

  export const mapBackendToFrontend = (category) => {
    const mapping = {
      'M1ra': '1ra', 'M2da': '2da', 'M3ra': '3ra', 'M4ta': '4ta',
      'M5ta': '5ta', 'M6ta': '6ta', 'FA': 'A', 'FB': 'B', 'FC': 'C', 'FD': 'D'
    };
    return category ? (mapping[category] || 'Sin categoría') : 'Sin categoría';
  };
  
  export const mapBackendToFrontendUser = (category) => {
    const mapping = {
        'Primera masculino': '1ra', 'Segunda masculino': '2da', 'Tercera masculino': '3ra', 'Cuarta masculino': '4ta', 'Quinta masculino': '5ta', 'Sexta masculino': '6ta', 
        'Primera femenino': 'A', 'Segunda femenino': 'B', 'Tercera femenino': 'C', 'Cuarta femenino': 'D'
    };
    return category ? (mapping[category] || 'Sin categoría') : 'Sin categoría';
  };


  // React Datepicker functions

  const days = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  export const locale = {
    localize: {
      day: n => days[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => "dd/MM/yyyy"
    }
  }

  // Schedule functions
  
  export const calculateTotalBookings = (bookings) => bookings.length;

  export const getPaymentStatusClass = (schedule) => {
    switch (schedule?.payment_status) {
        case 'Pagado':
            return 'go-schedules-blocks-item--p100';
        case 'Abonado':
            return 'go-schedules-blocks-item--p50';
        case 'Bloqueado':
            return 'go-schedules-blocks-item--blocked';
        case 'Clases':
            return 'go-schedules-blocks-item--clases';
        case 'GoMatch':	
            return 'go-schedules-blocks-item--gomatch';
        case 'Reservado':	
            return 'go-schedules-blocks-item--reservado';
        default:
            return 'go-schedules-blocks-item--available-v2';
    }
  };

  export const getBookingStatus = (bookingStatus) => {
    switch (bookingStatus) {
        case 'p0':
            return 'GoMatch';
        case 'p50':
            return 'Abonado';
        case 'pr':
            return 'Reservado';
        case 'pc':
            return 'Clases';
        case 'pb':	
            return 'Bloqueado';
        case 'p100':	
            return 'Pagado';
        default:
            return 'GoMatch';
    }
  }

  export const scheduleStatus = (schedule) => {

    switch (schedule.payment_status) {
        case 'Pagado':
            return 'pagado';
        case 'Abonado':
            return 'abonado';
        case 'Bloqueado':
            return 'bloqueado';
        case 'Clases':
            return 'clases';
        case 'GoMatch':
            return 'gomatch';
        case 'Reservado':
              return 'Reservado';
        default:
            return 'disponible';
    }

  }

  export const isScheduleDisabled = (schedule, nextSchedule, selectedDuration, index, schedules) => {
    const lastBlockIndex = schedules.length - 1;
    const durationInBlocks = selectedDuration === 5 ? 1 : 0;

    if (index >= lastBlockIndex - durationInBlocks && !schedule.available) {
        return '';
    }

    if (index >= lastBlockIndex - durationInBlocks) {
        return 'pdl-schedules-item--disabled';
    }

    return schedule.available && nextSchedule && (nextSchedule.available === false) && schedule.start_time ? 'pdl-schedules-item--disabled' : '';
  };

  export const getHighlightClass = (schedule, index, schedules, hoveredItemId, selectedDuration) => {
    const hoveredIndex = schedules.findIndex(s => s.id === hoveredItemId);

    if (hoveredIndex === -1 || !schedules[hoveredIndex].available) return '';

    const hoveredSchedule = schedules[hoveredIndex];
    const isHoveredItemBuilding = hoveredSchedule.bookings.length !== 0 && hoveredSchedule.payment_status === null;

    const durationInBlocks = selectedDuration === 3 ? 0 : selectedDuration === 4 ? 1 : 2;
    const isHoveredItemLastOrSecondToLast = hoveredIndex >= schedules.length - durationInBlocks;

    let nextAvailableCount = 0;
    let nextAvailable = true;
    for (let i = hoveredIndex + 1; i < schedules.length; i++) {
        if (!schedules[i].available) {
            nextAvailable = false;
            break;
        }
        nextAvailableCount++;
        if (nextAvailableCount >= durationInBlocks) break;
    }

    if (!nextAvailable || isHoveredItemLastOrSecondToLast) return '';

    const highlightBuildingClass = isHoveredItemBuilding ? ' highlight-building' : '';

    if (index === hoveredIndex) return 'highlight-1' + highlightBuildingClass;

    for (let i = 1; i <= durationInBlocks; i++) {
        if (index === hoveredIndex + i) {
            return `highlight-${i + 1}` + highlightBuildingClass;
        }
    }

    return '';
  };

  export const canTriggerClick = (schedules, selectedDuration) => {
    if (!schedules) return false;

    const highlights = schedules.map(schedule => getHighlightClass(schedule, schedules.indexOf(schedule), schedules));
    
    if (selectedDuration === 3) {
        return highlights.includes('highlight-1');
    }
    
    if (selectedDuration === 4) {
        return highlights.includes('highlight-2');
    }
    
    if (selectedDuration === 5) {
        return highlights.includes('highlight-2') && highlights.includes('highlight-3');
    }
    
    return false;
};

 


  


