export const user_role_type = {
  PUBLIC: "public",
  AUTHENTICATED: "authenticated",
  CLUB_ADMIN: "club_administrator",
}

export const invitado = {
  id: 44
}

export const booking_status = {
  PAGADO: "Pagado",
  POR_PAGAR: "Por pagar",
  RESERVADO: "Reservado",
  ANULADO: "Anulado",
}

export const schedule_reservation_type = {
  GOMATCH: "GoMatch",
  PAGADO: "Pagado",
  ABONADO: "Abonado",
  CLASES: "Clases",
  RESERVADO: "Reservado",
  BLOQUEADO: "Bloqueado",
}

export const bucketUrl = "https://storage.googleapis.com/gomatchbucket";


export const possible_schedule_durations = [
    {
      "id": 0,
      "duration_int": 10,
      "duration_text": "10 minutos"
    },
    {
      "id": 1,
      "duration_int": 15,
      "duration_text": "15 minutos"
    },
    {
      "id": 2,
      "duration_int": 30,
      "duration_text": "30 minutos"
    },
    {
      "id": 3,
      "duration_int": 45,
      "duration_text": "45 minutos"
    },
    {
      "id": 4,
      "duration_int": 60,
      "duration_text": "1 hora"
    },
    {
      "id": 5,
      "duration_int": 90,
      "duration_text": "1 hora y 30 minutos"
    },
    {
      "id": 6,
      "duration_int": 120,
      "duration_text": "2 horas"
    }
]

export const enabled_services = [
    {
      "id": 1,
      "name": "GoMatch"
    },
    {
      "id": 2,
      "name": "GoMarket"
    },
    {
      "id": 3,
      "name": "GoRegisters"
    }
]
  
