import "./App.scss";
import React, { useState, useEffect, Fragment } from "react";
import { useRoutes, useLocation, matchPath  } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css';
import "react-toastify/dist/ReactToastify.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "moment/locale/es.js";
import Header from "./components/Global/Header/Header";
import SideBar from "./components/Global/GoSidebar/GoSidebar";
import ScrollUtil from "./utils/ScrollUtil";
import CheckPrivateRoute from "./utils/Routing/CheckPrivateRoute";
import CheckAdminPrivateRoute from "./utils/Routing/CheckAdminPrivateRoute";
import { publicRoutes } from "./utils/Routing/publicRoutes";
import { privateRoutes } from "./utils/Routing/privateRoutes";
import { privateAdminRoutes } from "./utils/Routing/privateAdminRoutes";
import { fallbackRoutes } from "./utils/Routing/fallbackRoutes";
import { isTokenExpired } from "./utils/Functions";
import { CashRegisterProvider } from './contexts/GoMarket/CashRegisterContext';


const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handleResize = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return isMobile;
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const isMobile = useIsMobile();
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if(isMobile) {
      setIsSidebarOpen(false);
    }
    const updateLoginStatus = () => {
      const token = localStorage.getItem("userToken");
      setIsLoggedIn(!!token);
      isTokenExpired(); // Check if the token has expired
    };

    updateLoginStatus();
    window.addEventListener("storage", updateLoginStatus);

    return () => {
      window.removeEventListener("storage", updateLoginStatus);
    };
  }, [location, isMobile]);

  const parseRouteObjects = (routes, isPrivateRoute = false, isAdminRoute = false) => {
    return routes.map((route) => ({
      path: route.path,
      element: isPrivateRoute && !isAdminRoute ? (
        <CheckPrivateRoute >{route.element}</CheckPrivateRoute>
      ) : isPrivateRoute && isAdminRoute ? (
        <CheckAdminPrivateRoute >{route.element}</CheckAdminPrivateRoute>
      ) : (
        route.element
      ),
    }));
  };

  const routes = useRoutes([
    ...parseRouteObjects(publicRoutes),
    ...parseRouteObjects(privateRoutes, true),
    ...parseRouteObjects(privateAdminRoutes, true, true),
    ...parseRouteObjects(fallbackRoutes)
  ]);

  const isCurrentRoute = (routes) => {
    return routes.some(route => matchPath(route.path, location.pathname));

  };

  const isPublicRoute = isCurrentRoute(publicRoutes);
  const isPrivateRoute = isCurrentRoute(privateAdminRoutes);

  const containerClasses = [];

  if (!isPublicRoute) {
    containerClasses.push('container', 'go-main', 'mt-5', 'pb-3');
  }

  if (isSidebarOpen && !isPublicRoute) {
    containerClasses.push('go-container');
  }

  return (
    <CashRegisterProvider> 
        <div className={containerClasses.join(' ')}>
        <AppContent
          isLoggedIn={isLoggedIn}
          location={location}
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          routes={routes}
          isPrivateRoute={isPrivateRoute}
        />
     </div>
    </CashRegisterProvider>
  );
}

function AppContent({ isLoggedIn, toggleSidebar, isSidebarOpen, setIsSidebarOpen, routes, isPrivateRoute }) {

  return (
    <Fragment> 
      <ScrollUtil />
      <ToastContainer />
      {isLoggedIn && isPrivateRoute && (
        <>
          <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
          <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
        </>
      )}

      {routes}
    </Fragment>
  );
}

export default App;
