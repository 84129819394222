const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:1337';

export const globalAdminState = async () => {
    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/global_state`, {
        method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        return { ok: true, data: data };
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
};

// Notifications

export const getNotifications = async (page = 1, limit = 10) => {
    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/notification/get_notifications?page=${page}&limit=${limit}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();

        if (response.ok) {
            // Handle the case where data is null due to no notifications found
            if (!data.data) {
                return { ok: true, data: [], pagination: { totalPages: 1 } };
            }

            // Ensure `data` is an array
            if (!Array.isArray(data.data)) {
                console.error("Expected an array but got:", data.data);
                return { ok: false, message: "Unexpected data format" };
            }

            return { ok: true, data: data.data, pagination: data.pagination };
        } else if (response.status === 404) {
            // Return an empty array and set pagination to 1 page without warning
            return { ok: true, data: [], pagination: { totalPages: 1 } };
        } else {
            throw new Error(data?.message || "Failed to fetch notifications");
        }
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
};

export const getUnreadNotifications = async (page = 1, limit = 10) => {
    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/notification/get_unread_notifications?page=${page}&limit=${limit}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();

        if (response.ok) {
            // Handle the case where data is null due to no notifications found
            if (!data.data) {
                return { ok: true, data: [], pagination: { totalPages: 1 } };
            }

            // Ensure `data` is an array
            if (!Array.isArray(data.data)) {
                console.error("Expected an array but got:", data.data);
                return { ok: false, message: "Unexpected data format" };
            }

            return { ok: true, data: data.data, pagination: data.pagination };
        } else if (response.status === 404) {
            // Return an empty array and set pagination to 1 page without warning
            return { ok: true, data: [], pagination: { totalPages: 1 } };
        } else {
            throw new Error(data?.message || "Failed to fetch unread notifications");
        }
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
};



export const archiveNotificationByNotificationId = async (notificationId) => {

    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/notification/archive_notification/${notificationId}`, {
        method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to archive notification by notification id");
        }
        return { ok: true };
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
}

export const readMultipleNotifications = async (notificationIds) => {
    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/notification/read_multiple_notifications`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(notificationIds),  // Stringify the array
        });
        if (!response.ok) {
            throw new Error("Failed to read multiple notifications by notification ids");
        }
        return { ok: true };
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
}


export const readAllNotifications = async () => {

    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/notification/read_all_notifications`, {
        method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to read all notifications");
        }
        return { ok: true };
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
}
// Reports

export const getOrdersAmountReportSplitByOrderStatus = async (period, start_date, end_date) => {

    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/order/get_orders_amount_report_split_by_order_status?period=${period}&start_date=${start_date}&end_date=${end_date}`, {
        method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to fetch orders amount report split by order status");
        }
        const data = await response.json();
        return { ok: true, data: data };
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
}

export const getSalesReport = async (start_date, end_date) => {
    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/order/get_sales_report?start_date=${start_date}&end_date=${end_date}&date_sort=DESC`, {
        method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to fetch sales report");
        }
        const data = await response.json();
        return { ok: true, data: data };
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
}

export const getSalesPeriodPerformanceReport = async (period) => {

    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/order/get_sales_period_performance_report?period=${period}`, {
        method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to fetch sales period performance report");
        }
        const data = await response.json();
        return { ok: true, data: data };
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
}

export const getPayedOrdersCountReportSplitByItemsCategory = async (period) => {

    try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${BASE_URL}/api/admin/order/get_payed_orders_count_report_split_by_items_category?period=${period}`, {
        method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to fetch sales period performance report");
        }
        const data = await response.json();
        return { ok: true, data: data };
    } catch (error) {
        console.error("Error:", error);
        return { ok: false, message: error.toString() };
    }
}
