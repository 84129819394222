import React, { useState, useRef, useEffect, useContext } from "react";
import { Row, Col, Badge, Button, Tab, Nav } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import { CashRegisterContext } from "../../../contexts/GoMarket/CashRegisterContext";
import {
  getUnreadNotifications,
  getNotifications,
  readAllNotifications,
  readMultipleNotifications,
  archiveNotificationByNotificationId,
} from "../../../utils/GlobalApi";
import GoPagination from "../Pagination/GoPagination";

const Notifications = ({ iconSrc, dashboard = false }) => {
  const [showNotifications, setShowNotifications] = useState(dashboard);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [totalPagesAll, setTotalPagesAll] = useState(1);
  const [currentPageUnread, setCurrentPageUnread] = useState(1);
  const [totalPagesUnread, setTotalPagesUnread] = useState(1);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("unread"); 
  const { currentNotifications, setCurrentNotifications, refreshCashRegisters } = useContext(CashRegisterContext);
  const [animationClass, setAnimationClass] = useState(""); 
  
  const limit = 5;

  const toggleNotifications = () => {
    if (!showNotifications) {
      setAnimationClass("is-visible"); 
      setShowNotifications(true); 
      fetchNotifications(); 
    } else {
      setAnimationClass("is-hidden"); 
      setTimeout(() => {
        setShowNotifications(false);
      }, 300); 
    }
  };

  const notificationButtonRef = useRef(null);
  const notificationOverlayRef = useRef(null);

  useEffect(() => {
    if (dashboard) {
      fetchNotifications(); // Initial fetch when dashboard is true
    }
  }, [dashboard]);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      if (activeTab === "unread") {
        await fetchUnreadNotifications(currentPageUnread);
      } else {
        await fetchAllNotifications(currentPageAll);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
    setLoading(false);
  };

  const fetchAllNotifications = async (page = 1) => {
    const response = await getNotifications(page, limit);
    if (response.ok) {
        setAllNotifications(response.data);
        setTotalPagesAll(response.pagination.totalPages);
    } else {
        console.error("Failed to fetch all notifications:", response?.message);
    }
  };
  
  const fetchUnreadNotifications = async (page = 1) => {
    const response = await getUnreadNotifications(page, limit);
    if (response.ok) {
        setUnreadNotifications(response.data);
        setTotalPagesUnread(response.pagination.totalPages);
    } else {
        console.error("Failed to fetch unread notifications:", response?.message);
    }
  };

  const removeNotificationFromState = (id) => {
    setAllNotifications((prev) => prev.filter((n) => n.id !== id));
    setUnreadNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  const handlePageChangeAll = (page) => {
    setCurrentPageAll(page);
    fetchAllNotifications(page);
  };

  const handlePageChangeUnread = (page) => {
    setCurrentPageUnread(page);
    fetchUnreadNotifications(page);
  };

  const handleTabChange = (key) => {
    setActiveTab(key); // Update active tab state
    if (key === "all") {
      fetchAllNotifications(currentPageAll);
    } else if (key === "unread") {
      fetchUnreadNotifications(currentPageUnread);
    }
  };

  const handleReadAllNotifications = async () => {
    const response = await readAllNotifications();
    if (response.ok) {
      setAllNotifications((prev) => prev.map((n) => ({ ...n, notification_status: "read" })));
      setUnreadNotifications([]);
      setCurrentNotifications('');
      refreshCashRegisters();
    } else {
      console.error("Failed to mark all notifications as read:", response?.message);
    }
  };

  const handleArchiveNotification = async (id) => {
    const response = await archiveNotificationByNotificationId(id);
    if (response.ok) {
      setAllNotifications((prev) => prev.filter((n) => n.id !== id));
      setUnreadNotifications((prev) => prev.filter((n) => n.id !== id));
      refreshCashRegisters();
    } else {
      console.error("Failed to archive notification:", response?.message);
    }
  };

  useEffect(() => {
    if (!dashboard) {
      const handleClickOutside = (event) => {
        if (
          notificationOverlayRef.current &&
          !notificationOverlayRef.current.contains(event.target) &&
          notificationButtonRef.current &&
          !notificationButtonRef.current.contains(event.target)
        ) {
          setShowNotifications(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [dashboard]);

  return (
    <div className={`notification-container ${dashboard ? "dashboard-mode" : ""}`}>
      {!dashboard && (
        <Button
          className="me-2 go-btn go-btn--theme-switch"
          onClick={toggleNotifications}
          ref={notificationButtonRef}
        >
          <img src={iconSrc} alt="Notifications" />
          {currentNotifications && <div className="ms-2 go-notification-badge"></div>}
        </Button>
      )}
      {showNotifications && (
    <div
    className={`go-notification-overlay ${dashboard ? "dashboard-list is-visible" : ""} ${animationClass}`}
    ref={notificationOverlayRef}
  >
          {!dashboard && (
            <Row>
              <Col md={10}>
                <h4 className="mb-2">
                  Notificaciones{" "}
                  {unreadNotifications.length > 0 && (
                    <Badge className="go-badge go-badge--notifications">
                      {unreadNotifications.length === 1 ? '1 nueva' : `${unreadNotifications.length} nuevas`}
                    </Badge>
                  )}
                </h4>
                <Button className="read-all-notifications" onClick={handleReadAllNotifications}>
                  Marcar todas como leídas
                </Button>
              </Col>
              <Col md={2}></Col>
            </Row>
          )}
          <Tab.Container defaultActiveKey="unread" onSelect={handleTabChange}>
            <Nav variant="tabs" className="go-notification-tabs">
              <Nav.Item>
                <Nav.Link eventKey="unread">No leídas</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="all">Todas</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="go-notification-overlay-body">
              <Tab.Pane eventKey="all">
                {loading ? (
                  <Loader insideModal={true} />
                ) : allNotifications.length > 0 ? (
                  <>
                    {allNotifications.map((notification) => (
                      <NotificationLink
                        key={notification.id}
                        notification={notification}
                        dashboard={dashboard}
                        onArchive={() => handleArchiveNotification(notification.id)}
                        removeNotificationFromState={removeNotificationFromState} // Pass the remove function
                        toggleNotifications={toggleNotifications} // Pass the toggle function

                      />
                    ))}
                  </>
                ) : (
                  <p className="information">No hay notificaciones</p>
                )}
              </Tab.Pane>

              <Tab.Pane eventKey="unread">
                {loading ? (
                  <Loader insideModal={true} />
                ) : unreadNotifications.length > 0 ? (
                  <>
                    {unreadNotifications.map((notification) => (
                      <NotificationLink
                        key={notification.id}
                        notification={notification}
                        dashboard={dashboard}
                        onArchive={() => handleArchiveNotification(notification.id)}
                        removeNotificationFromState={removeNotificationFromState} // Pass the remove function
                        toggleNotifications={toggleNotifications} // Pass the toggle function
                      />
                    ))}
                  </>
                ) : (
                  <p className="information">No hay nuevas notificaciones</p>
                )}
              </Tab.Pane>
            </Tab.Content>
            {dashboard && activeTab === "all" && (
              <div className="pt-3">
                <GoPagination
                  page={currentPageAll}
                  totalPages={totalPagesAll}
                  onPageChange={handlePageChangeAll}
                />
              </div>
            )}
            {dashboard && activeTab === "unread" && (
              <div className="pt-3">
                <GoPagination
                  page={currentPageUnread}
                  totalPages={totalPagesUnread}
                  onPageChange={handlePageChangeUnread}
                />
              </div>
            )}
          </Tab.Container>
        </div>
      )}
    </div>
  );
};

const NotificationLink = ({ notification, removeNotificationFromState, toggleNotifications }) => {
  const imgSrc = getImageSrc(notification.notification_type);
  const { refreshCashRegisters } = useContext(CashRegisterContext);

  const handleNotificationClick = async () => {
    try {
      await readMultipleNotifications([notification.id]);
      refreshCashRegisters(); 
      removeNotificationFromState(notification.id); 
      toggleNotifications(); 
    } catch (error) {
      console.error("Error reading notification:", error);
    }
  };

  return (
    <Link
      to={notification.notification_link}
      className={`notification-link ${notification.notification_status}`}
      onClick={handleNotificationClick}
    >
      <NotificationItem
        imgSrc={imgSrc}
        message={notification.notification_title}
        details={notification.notification_message}
        time={notification.notification_sent_at_text}
      />
      {notification.notification_status === "unread" && (
        <Button
          variant="link"
          className="archive"
          onClick={handleNotificationClick}
        >
          Marcar como leída
        </Button>
      )}
    </Link>
  );
};

const NotificationItem = ({ imgSrc, message, details, time }) => (
  <Row className=" p-2 pb-0">
    <Col md={2} xs={2} className="text-center my-auto">
      <div className="img-div">
        <LazyLoadImage effect="blur" src={imgSrc} alt="img" />
      </div>
    </Col>
    <Col md={7} xs={7} className="my-auto ps-md-2">
      <p className="mb-0 message">{message}</p>
      <p className="mb-0 details">{details}</p>
    </Col>
    <Col md={3} xs={3} className="my-auto">
      <p className="mb-0 time">{time}</p>
    </Col>
  </Row>
);

const getImageSrc = (status) => {
  switch (status) {
    case "critical_stock":
      return "/assets/icons/danger.svg";
    case "welcome":
      return "/assets/icons/heart.svg";
    case "maintenance":
      return "/assets/icons/flash.svg";
    default:
      return "/assets/icons/menu/box.svg"; // Default icon
  }
};

export default Notifications;
