import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { faHouse } from "@fortawesome/free-solid-svg-icons";

function FallbackScreen() {
  const navigate = useNavigate();

  return (
    <div>
      <h1 className='pdl-title-main'>Whoops! Territorio inexplorado</h1>
      <h4 className='text-center'>La ruta a la que intentas acceder no existe o no tienes acceso.</h4>
      <div className="py-2">
          <Button onClick={() => navigate("/")} className="w-100">
            <FontAwesomeIcon icon={faHouse} className="me-2" />
            Ir a inicio
          </Button>
        </div>
    </div>
  );
}

export default FallbackScreen;