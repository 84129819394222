import React, { useState, useRef, useContext, useEffect } from 'react';
import { Nav, Modal, Button, Row, Col } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CashRegisterContext } from '../../../contexts/GoMarket/CashRegisterContext';
import { enabled_services as serviceMapping } from '../../../utils/Constants'; // Import the service mapping

const Sidebar = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [activePath, setActivePath] = useState(currentPath);
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const imgRefs = useRef({});

  const { currentPendingOrders, currentDailyBookings, enabledServices } = useContext(CashRegisterContext);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.matchMedia("(min-width: 768px)").matches);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isActive = (path, exact = false) => {
    return exact ? activePath === path : activePath.startsWith(`${path}/`) || activePath === path;
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/admin_login');
  };

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setShowLogoutModal(true);
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const handleNavLinkClick = (path) => (event) => {
    if (path === '/logout') {
      handleLogoutClick(event);
    } else {
      setActivePath(path);
    }
  };

  const handleFillClick = () => {
    if (!isDesktop && isOpen) {
      setIsOpen(false)
    }
  };

  // Base navigation items
  const navItemBase = [
    { path: '/admin_dashboard', label: 'Dashboard', icon: 'home' },
    //{ path: '/admin/configuracion', label: 'Configurar', icon: 'gear' },
    //{ path: '/inteligencia-artifical', label: 'IA', icon: 'ai' },
    { path: '/logout', label: 'Salir', icon: 'logout', extraClass: 'mt-auto' },
  ];
  
  // Service-specific navigation items
  const navItemsGoMarket = [
    { path: '/admin/gomarket', label: 'Punto de venta', icon: 'shop', exact: true, badge: currentDailyBookings || 0 },
    { path: '/admin/gomarket/inventario', label: 'Inventario', icon: 'box' },
    { path: '/admin/gomarket/historial-cajas', label: 'Cajas', icon: 'strongbox' },
    { path: '/admin/ventas', label: 'Ventas', icon: 'ventas' },
    { path: '/admin/pendientes', label: 'Pendientes', icon: 'pendientes', badge: currentPendingOrders || 0 },
    { path: '/admin/reporteria', label: 'Reporteria', icon: 'stadistics' },
  ];

  const navItemsGoCalendar = [
    { path: '/admin/gocalendar', label: 'Calendarium', icon: 'calendar' },
  ];

  const navItemsGoBalance = [
    { path: '/admin/balances', label: 'Balances', icon: 'registers' },
  ];

  // Dynamically build the navigation items based on enabled services
  const navItems = [...navItemBase];


  enabledServices.forEach(serviceId => {
  const service = serviceMapping.find(s => s.id === serviceId);

  if (service) {
    if (service.name === 'GoMatch') {
      navItems.splice(1, 0, ...navItemsGoCalendar); // Insert after Dashboard (at index 1)
    } else if (service.name === 'GoMarket') {
      navItems.splice(1, 0, ...navItemsGoMarket); // Insert after Dashboard (at index 1)
    } else if (service.name === 'GoRegisters') {
      navItems.splice(1, 0, ...navItemsGoBalance); // Insert after Dashboard (at index 1)
    }
  }
});


  const renderNavLink = ({ path, label, icon, exact, extraClass, badge }) => {
    const isLinkActive = isActive(path, exact);
    return (
      <Nav.Link
        key={path}
        as={Link}
        to={path === '/logout' ? '#' : path}
        className={`${extraClass ? extraClass : ''} go-sidebar__item nav-link ${isLinkActive ? 'active' : ''}`}
        onClick={handleNavLinkClick(path)}
      >
        <img
          ref={(el) => (imgRefs.current[path] = el)}
          data-icon={icon}
          src={`/assets/icons/menu/${icon}.svg`}
          alt={label}
        />
        {label}
        {badge !== undefined && badge > 0 && (
          <div bg="danger" className={`ms-2 notification-badge ${badge ? '' : 'd-none'}`} />
        )}
      </Nav.Link>
    );
  };

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : ''} go-sidebar`}>

        <Nav className="flex-column h-100 pb-3">
          <Link to='/admin_dashboard' className='go-header__brand ps-4 pt-3 pb-4'>
            <span className='go-header__title'> Inventarium </span>
          </Link>
          {navItems.slice(0, 3).map(renderNavLink)}
          {navItems.slice(3, 8).map(renderNavLink)}
          {navItems.slice(8).map(renderNavLink)}
        </Nav>
      </div>

      {!isDesktop && isOpen && (
        <div className='go-sidebar-fill' onClick={handleFillClick} />
      )}

      <Modal show={showLogoutModal} className='go-modal go-modal--payment' centered onHide={handleCloseLogoutModal}>
        <Modal.Body>
          <h4 className='title'>Estás seguro que quieres salir?</h4>
          <p className='go-text'>Te esperaremos aquí mismo :)</p>
          <Row className='pt-4'>
            <Col className="col-md-6 col-12">
              <Button variant="secondary" className='go-btn go-btn--close' onClick={handleCloseLogoutModal}>
                Volver
              </Button>
            </Col>
            <Col className="col-md-6 col-12">
              <Button variant="primary" className='go-btn go-btn--add' onClick={handleLogout}>
                Cerrar sesión
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
