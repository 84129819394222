import React, { useState, useContext } from 'react';
import './Header.scss';
import { Col, Row, Button, Container, Badge } from 'react-bootstrap';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import CashRegisterLogic from '../../GoMarket/CashRegisterLogic';
import { CashRegisterContext } from '../../../contexts/GoMarket/CashRegisterContext';
import Notifications from '../Notifications/Notifications';

const Header = ({ toggleSidebar, isSidebarOpen }) => {
  const [showOpenModal, setShowOpenModal] = useState(false);
  const {
    isRegisterOpen,
    setIsRegisterOpen,
    currentUsername,
    currentUserId,
    enabledServices,
    cashRegisters,
    setCashRegisters,
    isDarkMode,
    toggleTheme,
  } = useContext(CashRegisterContext);

  return (
    <div className="go-header">
      <Container className={`go-main go-main--header ${!isSidebarOpen ? '':'go-container'}`}>
        <Row>
          <Col md={8} xs={4} className='my-auto'>
            <Row>
              <Col md={1} className='my-auto'>
              <Button className="me-2 go-btn go-btn--theme-switch" onClick={toggleSidebar}>
              <img
                className="go-header__toggle"
                src="/assets/icons/toggle.svg"
                alt="Toggle Sidebar"
                
              />
            </Button>
              </Col>
              <Col md={10}>
                  <div className="d-md-block d-none">
                  <Breadcrumb />
                </div>
              </Col>
            </Row>
            
          </Col>
          <Col md={4} xs={8} className="text-end my-auto">
            {/* Dark mode toggle */}
            <Button onClick={toggleTheme} className="me-2 go-btn go-btn--theme-switch">
              {isDarkMode ? (
                <img src="/assets/icons/moon.svg" alt="Dark Mode" />
              ) : (
                <img src="/assets/icons/sun.svg" alt="Light Mode" />
              )}
            </Button>

            {/* Notifications */}
            <Notifications iconSrc='/assets/icons/notification.svg' />

            {/* Conditional buttons based on enabled services */}
            {enabledServices.includes(2) && (
              <Button
                className="go-btn go-btn--cash me-2"
                onClick={() => setShowOpenModal(true)}
              >
                <img
                  className="go-header__notifications"
                  src="/assets/icons/strongbox.svg"
                  alt="Cash Register"
                />
                <span className="go-header__cash-text">
                  {isRegisterOpen ? (
                    <span className="go-color--green">
                      <Badge className="go-badge go-badge--open"> Abierta </Badge>
                    </span>
                  ) : (
                    <span className="go-color--black">
                      <Badge className="go-badge go-badge--closed"> Cerrada </Badge>
                    </span>
                  )}
                </span>
              </Button>
            )}

            {/* {enabledServices.includes(1) && (
              <Link to={`/user/${currentUsername}`}>
                <Button className="me-2 go-btn go-btn--theme-switch">
                  <img
                    className="go-header__user"
                    src="/assets/icons/profile.svg"
                    alt="User Profile"
                  />
                </Button>
              </Link>
            )} */}
          </Col>
        </Row>
      </Container>

      <CashRegisterLogic
        showOpenModal={showOpenModal}
        setShowOpenModal={setShowOpenModal}
        setRegisterOpen={setIsRegisterOpen}
        setSelectedCashRegister={() => {}}
        cashRegisters={cashRegisters}
        setCashRegisters={setCashRegisters}
        currentUserId={currentUserId}
      />
    </div>
  );
};

export default Header;
