import React, { lazy, Suspense } from "react";

// Lazy loading screens
const LoginScreen = lazy(() => import("../../screens/GoMatch/LoginScreen"));
const HomeScreen = lazy(() => import("../../screens/GoMatch/HomeScreen"));
const RegisterScreen = lazy(() => import("../../screens/GoMatch/RegisterScreen"));
const BuildingMatchScreen = lazy(() => import("../../screens/GoMatch/BuildingMatchScreen"));
const AdminLoginScreen = lazy(() => import("../../screens/Admin/AdminLoginScreen"));
const PasswordResetScreen = lazy(() => import("../../screens/GoMatch/PasswordResetScreen"));
const PaymentScreen = lazy(() => import("../../screens/GoMatch/PaymentScreen"));
const TermsAndConditionsScreen = lazy(() => import("../../screens/GoMatch/TermsAndConditions"));
const InventariumScreen = lazy(() => import("../../screens/Landings/InventariumScreen"));

export const publicRoutes = [
  {
    path: "/",
    element: <Suspense ><HomeScreen /></Suspense>,
  },
  {
    path: "/terms_and_conditions",
    element: <Suspense ><TermsAndConditionsScreen /></Suspense>,
  },
  {
    path: "/login",
    element: <Suspense ><LoginScreen /></Suspense>,
  },
  {
    path: "/register",
    element: <Suspense ><RegisterScreen /></Suspense>,
  },
  {
    path: "/match/:slug",
    element: <Suspense ><BuildingMatchScreen /></Suspense>,
  },
  {
    path: "/admin_login",
    element: <Suspense ><AdminLoginScreen /></Suspense>,
  },
  {
    path: "/password_reset",
    element: <Suspense ><PasswordResetScreen /></Suspense>,
  },
  {
    path: "/payment/:slug",
    element: <Suspense ><PaymentScreen /></Suspense>,
  },
  {
    path: "/inventarium",
    element: <Suspense ><InventariumScreen /></Suspense>,
  },
];
