import React, { createContext, useState, useEffect } from 'react';
import { globalAdminState } from '../../utils/GlobalApi';

export const CashRegisterContext = createContext();

export const CashRegisterProvider = ({ children }) => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [cashRegisters, setCashRegisters] = useState([]);
  const [roleType, setRoleType] = useState(null);

  // Initialize enabledServices with cached value or empty array
  const [enabledServices, setEnabledServices] = useState(() => {
    const cachedServices = JSON.parse(localStorage.getItem('enabledServices'));
    return cachedServices || [];
  });
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentUsername, setCurrentUsername] = useState(null);
  const [currentPendingOrders, setCurrentPendingOrders] = useState();
  const [currentDailyBookings, setCurrentDailyBookings] = useState();
  const [currentNotifications, setCurrentNotifications] = useState();
  const [currentName, setCurrentName] = useState(null);
  const [currentLastname, setCurrentLastname] = useState(null);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('isDarkMode') === 'true';
  });

  const fetchAdminState = async () => {
    const response = await globalAdminState();
    if (response.ok && response.data) {
      const adminState = response.data;
      setCashRegisters(adminState.cash_register);
      setCurrentUserId(adminState.user_id);
      setRoleType(adminState.user_role_type);
      setCurrentUsername(adminState.username);
      setCurrentName(adminState.name);
      setCurrentLastname(adminState.lastname);
      setCurrentPendingOrders(adminState.pending_orders);
      setCurrentDailyBookings(adminState.daily_pending_orders);
      setCurrentNotifications(adminState.has_unread_notifications);

      if (adminState.enabled_services) {
        setEnabledServices(adminState.enabled_services);
        // Cache the enabled services in localStorage
        localStorage.setItem('enabledServices', JSON.stringify(adminState.enabled_services));
      }
      console.log('global state refreshed')
    } else if (response.ok === false) {
      setRoleType('unauthorized');
    } else {
      console.error("Failed to fetch admin state:", response.message);
    }
  };

  useEffect(() => {
    fetchAdminState();
  }, []);

  useEffect(() => {
    if (currentUserId && cashRegisters.length > 0) {
      const currentRegisterOpen = cashRegisters.some(cr => cr.users_permissions_user?.id === currentUserId && cr.cash_register_open);
      setIsRegisterOpen(!!currentRegisterOpen);
    }
  }, [cashRegisters, currentUserId]);

  const toggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
    localStorage.setItem('isDarkMode', isDarkMode);
  }, [isDarkMode]);

  const refreshCashRegisters = async () => {
    await fetchAdminState();
  };

  return (
    <CashRegisterContext.Provider value={{ 
      isRegisterOpen, 
      setIsRegisterOpen, 
      cashRegisters, 
      setCashRegisters, 
      currentUserId, 
      setCurrentUserId,
      roleType,
      enabledServices,
      currentUsername,
      currentName,
      currentLastname,
      currentPendingOrders,
      currentDailyBookings,
      currentNotifications,
      setCurrentNotifications,
      refreshCashRegisters,
      isDarkMode,
      toggleTheme
    }}>
      {children}
    </CashRegisterContext.Provider>
  );
};
